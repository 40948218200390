// DEPENDIENCIES
import { useState } from 'react'

// COMPONENTS
import Content from "./components/Content"
import AppBackdrop from "./components/AppBackdrop"
import Header from './components/Header'

// MATERIAL UI
import { Box, CssBaseline } from "@mui/material"
import { makeStyles } from '@mui/styles'


const useStyles = makeStyles({
  ctn: {
    width: '100%',
    backgroundColor: '#f0f0f0',
    minHeight: '100vh'
  },
  contentCtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
})

const App = () => {

  // LOCAL STATE
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [backdropImgData, setBackdropImgData] = useState(null)

  // MATERIAL UI
  const classes = useStyles()

  return (
    <Box component="main" className={classes.ctn}>
      <CssBaseline />
      <Header />
      <Box className={classes.contentCtn}>
        <Content setIsBackdropOpen={setIsBackdropOpen} setBackdropImgData={setBackdropImgData}/>      
      </Box>
      <AppBackdrop isBackdropOpen={isBackdropOpen} setIsBackdropOpen={setIsBackdropOpen} backdropImgData={backdropImgData}/>
    </Box>
  )
}

export default App
