// DEPENDENCIES
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { data as peinturesData } from '../data/peintures'
import { data as temoignagesData } from '../data/temoignagnes'
import { data as dessinsData } from '../data/dessins'
import { data as diversData } from '../data/divers'
import { data as cartoonsData } from '../data/cartoons'

// COMPONENTS
import PeinturesItem from './PeinturesItem'
import DessinsItem from './DessinsItem'
import DiversItem from './DiversItem'
import TemoignagesItem from './TemoignagesItem'
import CartoonsItem from './CartoonsItem'


// MATERIAL UI
import { Box, Typography } from "@mui/material"
import { makeStyles } from '@mui/styles'


const useStyles = makeStyles((theme) => ({
  ctn: {
    width: '750px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	marginTop: '130px'
  },
  mainTitleCtn: {
	marginTop: '30px',
	marginBottom: '-35px'
  },
  mainTitle: {
	fontSize: '26pt !important',
  },
  titleCtn: {
	marginTop: '30px',
	marginBottom: '30px'
  },
  title: {
	fontSize: '20pt !important',
  },
  contentCtn: {
	
  },
  imgCtn: {
	width: '100%',
	marginTop: '30px',
	},
	img: {
		width: '100%'
	},
}))

const Content = (props) => {

	// PROPS
	const { setIsBackdropOpen, setBackdropImgData } = props

	// MATERIAL UI
	const classes = useStyles()

	// LOCATION
	const location = useLocation()

	useEffect(() => {
		console.log(location.pathname)
	}, [location])

	return (
		<Box className={classes.ctn}>

			{location.pathname === '/' ?
			<>
			<Box className={classes.titleCtn}>
				<Typography className={classes.title}>Peintures</Typography>
			</Box>
			<Box className={classes.contentCtn}>
				{peinturesData.map((item, index) => (
					<PeinturesItem data={item} setIsBackdropOpen={setIsBackdropOpen} setBackdropImgData={setBackdropImgData} index={index} length={peinturesData.length}/>
				))}
			</Box>			
			</>
 			: <></>}
			{location.pathname === '/temoignages' ?
			<>
			<Box className={classes.imgCtn}>
				<img className={classes.img} src='./assets/temoignages/cover.png' alt=""/>
			</Box>
			<Box className={classes.titleCtn}>
				<Typography className={classes.title}>Témoignages</Typography>
			</Box>
			<Box className={classes.contentCtn}>
				{temoignagesData.map((item, index) => (
					<TemoignagesItem data={item} setIsBackdropOpen={setIsBackdropOpen} setBackdropImgData={setBackdropImgData} index={index} length={peinturesData.length}/>
				))}
			</Box>
			</> : <></>
			}
			{location.pathname === '/dessins' ?
			<>
			<Box className={classes.titleCtn}>
				<Typography className={classes.title}>Dessins</Typography>
			</Box>
			<Box className={classes.contentCtn}>
				{dessinsData.map((item, index) => (
					<DessinsItem data={item} setIsBackdropOpen={setIsBackdropOpen} setBackdropImgData={setBackdropImgData} index={index} length={dessinsData.length}/>
				))}
			</Box>			
			</> : <></>}
			{location.pathname === '/divers' ?
			<>
			<Box className={classes.titleCtn}>
				<Typography className={classes.title}>Divers</Typography>
			</Box>
			<Box className={classes.contentCtn}>
				{diversData.map((item, index) => (
					<DiversItem data={item} setIsBackdropOpen={setIsBackdropOpen} setBackdropImgData={setBackdropImgData} index={index} length={diversData.length}/>
				))}
			</Box>			
			</> : <></>}
			{location.pathname === '/cartoons' ?
			<>
			<Box className={classes.titleCtn}>
				<Typography className={classes.title}>Cartoons</Typography>
			</Box>
			<Box className={classes.contentCtn}>
				{cartoonsData.map((item, index) => (
					<CartoonsItem data={item} setIsBackdropOpen={setIsBackdropOpen} setBackdropImgData={setBackdropImgData} index={index} length={cartoonsData.length}/>
				))}
			</Box>		
			</> : <></>}
		</Box>
	)
}

export default Content