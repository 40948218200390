// DEPENDENCIES
import { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"

// MATERIAL UI
import { Box, Typography } from "@mui/material"
import { makeStyles } from '@mui/styles'


const useStyles = makeStyles((theme) => ({
  ctn: {
    width: '100%',
	height: '130px',
	backgroundColor: '#1F2125',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	boxShadow: theme.shadows[5],
	position: 'fixed'
  },
  titleCtn: {
	flexBasis: '80px',
	display: 'flex',
	alignItems: 'center'
  },
  title: {
	color: 'white',
	fontSize: '27.5px !important'
  },
  navCtn: {
	width: '600px',
	display: 'flex',
	justifyContent: 'space-between'
  },
  btnItem: {
	  backgroundColor: 'transparent',
	  color: 'white',
	  outline: 'none',
	  border: 'none',
	  cursor: 'pointer',
	  fontSize: '16px',
	  fontFamily: 'Times New Roman',
	  textDecoration: 'none'
  },
  active: {
	  color: 'yellow',
	  textDecoration: 'underline'
  }
}))


const Header = (props) => {

	// MATERIAL UI
	const classes = useStyles()

	// ROUTER
	const location = useLocation()

	useEffect(() => {
		document.getElementById(location.pathname).classList.add(classes.active)
		return () => {
			document.getElementById(location.pathname).classList.remove(classes.active)
		}
	}, [location])

	return (
		<Box component="header" className={classes.ctn}>
			<Box className={classes.titleCtn}>
				<Typography className={classes.title}>Désiré Roegiest</Typography>
			</Box>
			<Box component="nav" className={classes.navCtn}>
				<Link className={classes.btnItem} id="/" to="/">Peintures</Link>
				<Link className={classes.btnItem} id="/temoignages" to="/temoignages">Témoignages</Link>
				<Link className={classes.btnItem} id="/dessins" to="/dessins">Dessins</Link>
				<Link className={classes.btnItem} id="/divers" to="/divers">Divers</Link>
				<Link className={classes.btnItem} id="/cartoons" to="/cartoons">Cartoons</Link>
			</Box>
		</Box>
	)
}

export default Header