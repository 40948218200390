export const data = [
	{
		imgSrc: '/assets/dessins/dessin-1.jpg',
		title: 'Dessin original pour la couverture du magazine « Magie Rouge »<br>consacré à Michel De Ghelderode.'
	},
	{
		imgSrc: '/assets/dessins/dessin-2.jpg',
		title: 'Couverture du magazine « Magie Rouge », consacré à quatre écrivains fantastiques belges.'
	},
	{
		imgSrc: '/assets/dessins/dessin-3.png',
		title: 'Jaquette de couverture de l’édition originale du roman radiophonique de Georges Simenon,<br>« Le soi-disant M. Prou ». – Edition « Les Amis de Georges Simenon ».'
	},
	{
		imgSrc: '/assets/dessins/dessin-4.jpg',
		title: 'Editions Le Monde, Paris.'
	},
	{
		imgSrc: '/assets/dessins/dessin-5.jpg',
		title: 'Illustration pour « Fantômas  - Editions Xérès, Paris.'
	},
	{
		imgSrc: '/assets/dessins/dessin-6.jpg',
		title: 'Illustration pour « Pardaillan – Editions Xérès, Paris.'
	},
	{
		imgSrc: '/assets/dessins/dessin-7.jpg',
		title: 'Illustration pour « Le Bossu » – Editions Xérès, Paris.'
	},
	{
		imgSrc: '/assets/dessins/dessin-8.jpg',
		title: 'Illustration pour « Le Capitaine Fracasse » – Editions Xérès, Paris.'
	},
	{
		imgSrc: '/assets/dessins/dessin-9.jpg',
		title: 'Illustration pour « Claudine à l’école » de Colette – Editions Xérès, Paris.'
	},
	{
		imgSrc: '/assets/dessins/dessin-10.jpg',
		title: 'Alfa Roméo P2 (1925) – Illustration à la gouache pour la brochure de présentation de l’émission philatélique consacrée aux 100 ans du circuit de Spa-Francorchamps en 1996.'
	},
	{
		imgSrc: '/assets/dessins/dessin-11.jpg',
		title: 'Mercedes-Benz W154 (1939) – Illustration à la gouache pour la brochure de présentation de l’émission philatélique consacrée aux 100 ans du circuit de Spa-Francorchamps en 1996.'
	},
	{
		imgSrc: '/assets/dessins/dessin-12.jpg',
		title: 'Paul Delvaux – Crayon et gouache acrylique.'
	},
	{
		imgSrc: '/assets/dessins/dessin-13.jpg',
		title: 'Georges Simenon – Frontispice pour « Les Cahiers Simenon » éd. Les Amis de Georges Simenon. Mine de plomb'
	},
	{
		imgSrc: '/assets/dessins/dessin-14.jpg',
		title: 'Illustration à la plume et encre de Chine pour « La Poule noire » de Thomas Owen.Signé du pseudonyme Lerouge.<br><br>« <i>La poule noire qui n’avait été que la projection matérialisée de Féla, se fondait imperceptiblement en elle, était absorbée, intégrée... »</i> [extrait].'
	},
	{
		imgSrc: '/assets/dessins/dessin-15.jpg',
		title: 'Linogravure pour le poème « Parfum exotique » de Charles Baudelaire.'
	},
]