// DEPENDENCIES
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from "react-router-dom"

// MATERIAL UI
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1F2125',
      light: '#363940'
    }
  },
  typography: {
    fontFamily: "Times New Roman"
  }
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>    
  </ThemeProvider>
,
  document.getElementById('root')
);
