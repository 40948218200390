// MATERIAL UI
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'


const useStyles = makeStyles((theme) => ({
	ctn: {

	},
	imgCtn: {
		display: 'flex',
		justifyContent: 'center',
		cursor: 'pointer'
	},
	img: {
		maxWidth: '285px'
	},
	title: {
		textAlign: 'center',
		fontSize: '12pt',
		margin: '5px',
		lineHeight: '13pt'
	},
	text: {
		textAlign: 'justify',
		fontSize: '12pt',
		lineHeight: '13pt'
	},
	separator: {
		marginTop: '50px',
		marginBottom: '50px',
		width: '75%',
		height: '1px',
		backgroundColor: theme.palette.primary.main,
		margin: 'auto'
	},
	author: {
		textAlign: 'right',
		fontSize: '12pt',
		lineHeight: '13pt',
		fontStyle: 'italic'
	},
	lastItem: {
		marginBottom: '30px'
	}
}))

const TemoignagesItem = (props) => {

	// PROPS
	const { data } = props

	// MATERIAL UI
	const classes = useStyles()

	return (
		<Box className={classes.ctn}>
			<p className={classes.text} dangerouslySetInnerHTML={{__html: data.text}}></p>
			<p className={classes.author} dangerouslySetInnerHTML={{__html: data.author}}></p>

			<Box className={classes.lastItem}></Box>
		</Box>
	)
}

export default TemoignagesItem