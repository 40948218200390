export const data = [
	{
		imgSrc: '',
		text: 'Désiré Roegiest est une sorte de visionnaire qui capte l’intériorité des paysages et des choses fixés dans une époque sans âge précis. Chacune de ses toiles est la preuve d’un voyage qui méritait d’être entrepris. Attentif à toutes les pulsations de l’imaginaire, il nous entraîne dans des fantasmes pleins de séduction en une sorte de jeu secret poétique nourri de sorts jetés au hasard.',
		author: 'Thomas OWEN</br>de l’Académie Royale de Littérature et de Langue françaises.'
	},
	{
		imgSrc: '',
		text: 'Au-delà de la réalité prosaïque, loin des rumeurs et de l’agitation, Désiré Roegiest nous prend par la main et nous invite à le suivre sur des chemins mystérieux ponctués d’étranges rencontres. Il est dévoué à la seule cause de la démarche poétique et s’est fait le scribe de ses rêves éveillés quand ses nuits oublient le temps.',
		author: 'Hélène FONTAINE</br>Critique d’art – Bruxelles.'
	},
	{
		imgSrc: '',
		text: 'A la vesprée, Roegiest s’évade, découvre des îles, des maisons, un univers parcouru de jeunes femmes mystérieuses. Son pinceau est gourmand dans la façon de captiver la lumière et réaliste dans le rendu de ce monde menacé par les eaux ou les brouillards de la nuit. L’artiste invite le visiteur à des voyages imaginaires où semble être calme et volupté, avec en toile de fond le désir lancinant de l’Éternel Retour.',
		author: 'Anita NARDON</br>Critique d’art – Bruxelles.'
	},
	{
		imgSrc: '',
		text: 'Désiré Roegiest est un artiste étonnant. Il met en scène des surfaces où jouent des reflets de lumière et des ombres. Et aussi toutes ces choses dont la diversité charme et surprend – ces filles nues, ces objets insolites, ces arbres noirs, ces chemins qui ne conduisent nulle part – sont toujours admirablement construits, peints avec autorité, soutenus par un coloris qui sait donner des émotions fortes.',
		author: 'Stéphane REY</br>Critique d’art – Bruxelles.'
	},
	{
		imgSrc: '',
		text: 'Loin des réalités oppressives voici l’évasion onirique. Les toiles de Désiré sont autant de fenêtres ouvertes sur un univers refuge, celui d’un peintre qui invite à l’accompagner dans ses pérégrinations secrètes qui nous guident jusqu’aux portes du songe au-delà desquelles se célèbrent les noces somptueuses de l’âme avec l’imaginaire.',
		author: 'Gaston BOGAERT</br>Artiste peintre – Saint-Martin-aux-Bois'
	},
	{
		imgSrc: '',
		text: 'L’observateur ne peut rester indifférent à cette œuvre, pour une foule de raisons ne serait-ce que la séduction à laquelle on succombe à chaque coup. Associations esthétiques de nudités voluptueusement impersonnelles et venues on ne sait d’où, objets communs mis en situations insolites, paysages nimbés d’une atmosphère étrange, la lumière... les ombres... le silence... l’inattendu, c’est un régal !',
		author: 'Fabienne SENOCQ</br>Journaliste artistique – Bruxelles'
	},
	{
		imgSrc: '',
		text: 'Je garde de mon enfance le souvenir d’un magicien habillé de lumière, faisant surgir de nulle part des milliers de foulards colorés sans que cela semble pouvoir s’arrêter. En franchissant les portes de l’univers de Désiré, j’ai cru un instant avoir retrouvé ce maître de l’imaginaire. Il était cette fois revêtu d’une âme, celle d’un artiste, couleur cœur.',
		author: 'Daniel SUETENS</br>Artiste peintre – Bruxelles'
	},
	{
		imgSrc: '',
		text: 'Il est réjouissant qu’il se trouve encore des plasticiens qui découvrent des chemins non-explorés, des voies réputées sans issue qu’il font déboucher sur des visions fascinantes. Roegiest est l’un de ces explorateurs qui nous entraine à sa suite de l’autre côté du miroir, là où l’artiste est à l’écoute du vrai mystère.',
		author: 'Hervé LeSICHEM</br>Chroniqueur d’art – Gand'
	},
	{
		imgSrc: '',
		text: 'La démarche de Désiré est celle d’un infatigable promeneur qui, à chaque détour de sa route, découvre des atmosphères encore non-visitées. Ses paysages infiniment nocturnes sont hantés par de belles dénudées qui guident le visiteur vers on ne sait quel destin. Les demeures qu’il met en scène ont gardé le souvenir de ceux qui les ont habitées... Le parcours de mon ami artiste est celui d’un semeur de songes.',
		author: 'Jacques DORMONT</br>Artiste peintre – Berchem-Ste-Agathe'
	},
	{
		imgSrc: '',
		text: 'Du tendre à l’insolite, les pinceaux de l’ami Roegiest font naître un monde de merveilleux et de nostalgie. Il conquiert l’espace de sa toile en aplats de velours nocturne ou l’éclaire de pâleurs évanescentes. Créateur d’une merveilleuse solitude, il rassemble les vestiges éparpillés dans sa mémoire et convie le spectateur à une magnifique évasion. Si son œuvre connaît aujourd’hui d’insignes faveurs, c’est qu’elle répond à un certain appétit de l’imaginaire.',
		author: 'Jean RIDEZ</br>Auteur-compositeur – Paris'
	},
	{
		imgSrc: '',
		text: 'L’art de Désiré Roegiest agit comme une douce narcose qui s’insinue en nous et nous aide à explorer ce monde intemporel qu’est le sien. C’est un monde très réel qui jouxte un univers onirique se situant entre le réalisme de l’esprit éveillé et l’illogisme qu’engendre le sommeil. Il suffit d’y croire pour y être introduit.',
		author: 'Alain VIRAY</br>Critique d’art – Bruxelles'
	},
	{
		imgSrc: '',
		text: 'La Belgique nous avait déjà étonnés par la qualité de ses peintres de l’imaginaire, René Magritte et Paul Delvaux entre autres, et nous ne sommes pas au bout de nos surprises. Digne héritier de ses grands aînés, voici Désiré Roegiest, un artiste qui visite lui aussi l’autre côté du miroir. Nous découvrons, étonnés, un peintre dont la vision débouche sur un espace qui nourrit le poète et satisfait le penseur. Désiré Roegiest a créé un univers ménageant l’évidence en lui conférant un aspect inattendu. Un monde tel qu’il est dans l’absolu de l’imaginaire.',
		author: 'Jean LARTÉGUY</br>Écrivain – Paris'
	},
	{
		imgSrc: '',
		text: 'Parmi les expositions actuelles, il en est une d’un confrère belge, Désiré Roegiest, qui mérite que l’on s’y attarde. On décèle chez ce peintre un pouvoir évocateur qui transcrit le royaume poétique de ses rêves. Aidé par une très belle technique, il met en scène des paysages nocturnes traversés par de jolis nus porteurs de lumière, et enrobe son œuvre d’une aura de silence.',
		author: 'Georges ARDITI</br>Artiste peintre – Paris'
	},
	{
		imgSrc: '',
		text: 'Au-delà de l’inconscient, l’incontrôlable, l’onirisme, le déferlement d’impulsions, l’œuvre de ce bel artiste est un imperceptible magma criblé d’énigmes et de fausses échappatoires. Et pourtant, à travers cet univers soumis à la grisaille ou à la lumière, à travers ce voile infini de l’étrange, on respire l’air du large. <i>(Traduction)</i>.',
		author: 'Emmy LEE</br>Chroniqueuse d’art – Amsterdam'
	},
	{
		imgSrc: '',
		text: 'On a l’occasion de découvrir ici un peintre dont la diversité de l’œuvre charme et surprend. Si la technique de Désiré Roegiest est d’une étonnante qualité (ce à quoi notre époque ne nous habitue plus guère) elle n’est pas un aboutissement mais le moyen que l’artiste met en œuvre pour faire croire à l’existence de ses rêves. Les sujets qu’il met en scène n’ont pu naître que dans la vieille Europe. On y découvre les relents de romantisme, des voyages dans l’antiquité et des paysages inconnus de ce côté-ci de l’océan. <i>(Traduction)</i>.',
		author: 'William S. FARELLI</br>Journaliste, chroniqueur d’art – New York'
	},
	{
		imgSrc: '',
		text: 'Désiré Roegiest est de ces peintres inclassables tout simplement parce qu’il est inutile de leur accoler une étiquette… Il y a chez cet artiste belge un « autre chose » d’indéfinissable qu’il serait vain de tenter de le décrypter. Son art agit un peu comme un guide touristique réalisé pour conduire le spectateur aux confins de l’inexplicable. <i>(Traduction)</i>.',
		author: 'Cris VAN HOUTEN</br>Critique – Amsterdam'
	},
]