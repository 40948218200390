// MATERIAL UI
import { Backdrop, IconButton, Box } from "@mui/material"
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'


const useStyles = makeStyles((theme) => ({
  ctn: {
	backgroundColor: `${theme.palette.primary.main} !important`,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center'
  },
  btn: {
	position: 'absolute !important',
	top: 0,
	right: 0,
	marginTop: '30px !important',
	marginRight: '30px !important',

	'& > svg': {
		fill: 'white'
	}
  },
  imgCtn: {
	maxWidth: '60%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center'
  },
  img: {
	width: '100%',
	maxHeight: '80%'
  },
  title: {
	color: 'white',
	textAlign: 'center',
	fontSize: '14pt',
	margin: '5px'
  },
  scale: {
	transform: 'scale(2)'
  }
}))


const AppBackdrop = (props) => {

	// PROPS
	const { isBackdropOpen, setIsBackdropOpen, backdropImgData } = props

	// MATERIAL UI
	const classes = useStyles()

	return (
		<Backdrop className={classes.ctn} open={isBackdropOpen}>
			<IconButton className={classes.btn} onClick={() => setIsBackdropOpen(false)}>
				<CloseIcon fontSize="large"/>
			</IconButton>
			<Box className={[classes.imgCtn]}>
				<img className={[classes.img, classes.scale]} src={backdropImgData ? backdropImgData.imgSrc : ''} alt=""/>
				<p className={classes.title} dangerouslySetInnerHTML={{__html: backdropImgData ? backdropImgData.title : ''}}></p>
			</Box>
		</Backdrop>
	)
}

export default AppBackdrop