export const data = [
	{
		imgSrc: '/assets/peintures/la_visiteuse.jpg',
		title: 'LA VISITEUSE - <i>huile sur toile, 150 x 280 cm.</i>',
		text: 'Au-delà de la réalité prosaïque, loin des rumeurs et de l’agitation, Désiré Roegiest nous prend par la main et nous invite à le suivre sur des chemins mystérieux ponctués d’étranges rencontres. Il est dévoué à la seule cause de la démarche poétique et s’est fait le scribe de ses rêves éveillés, quand ses nuits oublient le temps. <i>[Hélène Fontaine, critique d’art].</i>'
	},
	{
		imgSrc: '/assets/peintures/le_conciliabule.jpg',
		title: 'LE CONCILIABULE - <i>huile sur toile, 70 x 90 cm.</i>',
		text: ''
	},
	{
		imgSrc: '/assets/peintures/la_voyageuse.jpg',
		title: 'LA VOYAGEUSE - <i>huile sur toile, 100 x 120 cm.</i>',
		text: ''
	},
	{
		imgSrc: '/assets/peintures/le_voyage_imprudent.jpg',
		title: 'LE VOYAGE IMPRUDENT – <i>huile sur toile, 80 X 100 cm.</i>',
		text: ''
	},
	{
		imgSrc: '/assets/peintures/acces_interdit.jpg',
		title: "L'ACCÈS INTERDIT - <i>huile sur toile, 40 x 70 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/la_citadelle.jpg',
		title: 'L’EMPIRE DES NUAGES – <i>huile sur toile, 60 X 80 cm.</i>',
		text: ''
	},
	{
		imgSrc: '/assets/peintures/archipel_des_maisons_perdues.png',
		title: "L'ARCHIPEL DES MAISONS PERDUES - <i>huile sur toile, 65 x 92 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/la_maison_des_veilles.jpg',
		title: 'LA MAISON DES VEILLES - <i>huile sur toile, 60 x 80 cm.</i>',
		text: ''
	},
	{
		imgSrc: '/assets/peintures/la_prisonniere_du_marais.jpg',
		title: 'LA PRISONNIÈRE DU MARAIS - <i>huile sur toile, 100 x 120 cm.</i>',
		text: ''
	},
	{
		imgSrc: '/assets/peintures/la_retraite_des_dementes.jpg',
		title: 'LA RETRAITE DES DÉMENTES - <i>huile sur toile, 120 x 120 cm.</i>',
		text: 'D’après le conte éponyme de Thomas Owen – <i>« Elles sont bien moins folles qu’il n’y paraît et qu’on veut bien le faire croire. [...] Elles viennent attendre à la grille de la maison, nues et roses, été comme hiver... »</i> (Extrait).'
	},
	{
		imgSrc: '/assets/peintures/la_mort_du_petit_cheval.jpg',
		title: 'LA MORT DU PETIT CHEVAL - <i>huile sur toile, 80 x 100 cm.</i>',
		text: ''
	},
	{
		imgSrc: '/assets/peintures/les_brocanteuses.jpg',
		title: 'LES BROCANTEUSES - <i>huile sur toile, 80 x 100 cm.</i>',
		text: ''
	},
	{
		imgSrc: '/assets/peintures/la_fete_etrange.jpg',
		title: 'LA FÊTE ÉTRANGE - <i>huile sur toile, 40 x 65 cm.</i>',
		text: ''
	},
	{
		imgSrc: '/assets/peintures/les_sorcieres.jpg',
		title: 'LES SORCIÈRES - <i>huile sur panneau marouflé, 27 x 35 cm.</i>',
		text: ''
	},
	{
		imgSrc: '/assets/peintures/les_filles_de_neree.jpg',
		title: 'LES FILLES DE NÉRÉE - <i>huile sur toile, 97 x 135 cm.</i>',
		text: ''
	},
	{
		imgSrc: '/assets/peintures/ile_aux_mouettes.jpg',
		title: "L'ÎLE AUX MOUETTES - <i>huile sur toile, 40 x 50 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/la_dame_de_coventry.jpg',
		title: 'LA DAME DE COVENTRY - <i>huile sur toile, 45 x 60 cm.</i>',
		text: ''
	},
	{
		imgSrc: '/assets/peintures/les_naufragees.jpg',
		title: "LES NAUFRAGÉES - <i>huile sur toile, 40 x 50 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/les_parques.jpg',
		title: "LES PARQUES – <i>huile sur toile, 60 X 80 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/le_plat_pays.jpg',
		title: "LE PLAT PAYS - <i>huile sur toile, 80 x 100 cm.</i>",
		text: 'Désiré Roegiest a puisé son inspiration aux mêmes sources que Jacques Brel. Je retrouve le climat particulier et le regard que mon père portait sur le monde qui l’entourait. Ces paysages sont ceux qu’il a chantés. C’est la Flandre aux horizons perdus, ses ciels en lambeaux et les vraies nuances du plat pays. <i>[France Brel]</i>.'
	},
	{
		imgSrc: '/assets/peintures/la_naissance_daphrodite.jpg',
		title: "LA NAISSANCE D'APHRODITE - <i>huile sur toile, 70 x 80 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/le_petit_matin.jpg',
		title: "LE PETIT MATIN – <i>huile sur toile, 60 X 80.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/la_visiteuse_attendue.jpeg',
		title: "LA VISITEUSE ATTENDUE – <i>huile sur toile, 60 X 80 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/le_mal_hereditaire.jpg',
		title: "LE MAL HÉRÉDITAIRE – <i>huile sur toile, 45 X 55 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/retour_aux_sources.jpg',
		title: "LE RETOUR AUX SOURCES - <i>huile sur toile, 80 x 60 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/les_eaux_dormantes.jpg',
		title: "LES EAUX DORMANTES - <i>huile sur toile, 70 x 90 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/mergesemstein.jpg',
		title: "METZENGERSTEIN - <i>huile sur panneau, 50 x 60 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/les_oiseaux_de_lhiver.png',
		title: "LES OISEAUX DE L'HIVER - <i>huile sur toile, 65 x 92 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/la_barque_de_charon.jpg',
		title: "LA BARQUE DE CHARON - <i>huile sur panneau, 50 x 60 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/les_retrouvailles.png',
		title: "LES RETROUVAILLES - <i>huile sur panneau, 50 x 60 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/les_passageres.jpg',
		title: "LES PASSAGÈRES - <i>huile sur toile, 60 x 80 cm.</i>",
		text: ''
	},
	{
		imgSrc: '/assets/peintures/succube.jpg',
		title: "SUCCUBE - <i>huile sur panneau, 100 x 80 cm.</i>",
		text: ''
	},
]