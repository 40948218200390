export const data = [
	{
		imgSrc: '/assets/cartoons/cartoons-1.jpg'
	},
	{
		imgSrc: '/assets/cartoons/cartoons-2.jpg'
	},
	{
		imgSrc: '/assets/cartoons/cartoons-3.jpg'
	},
	{
		imgSrc: '/assets/cartoons/cartoons-4.jpg'
	},
	{
		imgSrc: '/assets/cartoons/cartoons-5.jpg'
	},
]