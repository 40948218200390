// MATERIAL UI
import { Box } from "@mui/material"
import { makeStyles } from '@mui/styles'


const useStyles = makeStyles((theme) => ({
	ctn: {

	},
	imgCtn: {
		display: 'flex',
		justifyContent: 'center',
		cursor: 'pointer'
	},
	img: {
		width: '100%'
	},
	title: {
		textAlign: 'center',
		fontSize: '14pt',
		margin: '5px'
	},
	text: {
		textAlign: 'justify',
		fontSize: '12pt',
		margin: '5px',
		lineHeight: '13pt',
		wordSpacing: '4.8px'
	},
	mb: {
		marginBottom: '30px'
	}
}))

const PeinturesItem = (props) => {

	// PROPS
	const { data, setIsBackdropOpen, setBackdropImgData } = props

	// MATERIAL UI
	const classes = useStyles()

	const handleClick = () => {
		setBackdropImgData(data)
		setIsBackdropOpen(true)
	}

	return (
		<Box>
			<Box className={classes.imgCtn} onClick={() => handleClick()}>
				<img className={classes.img} src={data.imgSrc} alt=""/>
			</Box>

			<p className={classes.title} dangerouslySetInnerHTML={{__html: data.title}}></p>
			<p className={classes.text} dangerouslySetInnerHTML={{__html: data.text}}></p>

			<Box className={classes.mb}></Box>
		</Box>
	)
}

export default PeinturesItem