// MATERIAL UI
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'


const useStyles = makeStyles((theme) => ({
	ctn: {
		marginBottom: '40px'
	},
	imgCtn: {
		display: 'flex',
		justifyContent: 'center',
		cursor: 'pointer'
	},
	img: {
		maxWidth: '100%'
	},
	title: {
		textAlign: 'center',
		fontSize: '12pt'
	},
	text: {
		textAlign: 'center',
		fontSize: '12pt',
		lineHeight: '13pt'
	},
	separator: {
		marginTop: '50px',
		marginBottom: '50px',
		width: '75%',
		height: '1px',
		backgroundColor: theme.palette.primary.main,
		margin: 'auto'
	},
	lastItem: {
		marginBottom: '30px'
	}
}))


const DiversItem = (props) => {

	// PROPS
	const { data, setIsBackdropOpen, setBackdropImgData } = props

	// MATERIAL UI
	const classes = useStyles()

	const handleClick = () => {
		setBackdropImgData(data)
		setIsBackdropOpen(true)
	}

	return (
		<Box className={classes.ctn}>
			<p className={classes.text} dangerouslySetInnerHTML={{__html: data.text}}></p>
			<Box className={classes.imgCtn} onClick={() => handleClick()}>
				<img className={classes.img} src={data.imgSrc} alt=""/>
			</Box>

			<p className={classes.title} dangerouslySetInnerHTML={{__html: data.title}}></p>

			<Box className={classes.lastItem}></Box>
		</Box>
	)
}

export default DiversItem